import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql, useStaticQuery} from 'gatsby';
import TouchScreen from '../layouts/TouchScreen';


const TouchScreenFr = () => {
  const data = useStaticQuery(graphql`
    query touchScreenDatasFr {
      image: file(relativePath: { eq: "touch/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulTypeDeTactile(sort: {fields: createdAt, order: ASC}, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            functionalities
            gif {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulTactile {
        edges {
          node {
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/touch-screens'
    slider={true}
    headerImage={data.image.childImageSharp} 
    headerImageAlt="Ecran tactile et application Attractif"
    title="Votre borne tactile développée sur mesure" 
    subTitle="Nous vous accompagnons dans la réalisation clé en main de votre projet tactile, que ce soit avec borne(s) intérieure(s) ou extérieure(s)." 
    anchorLink="#touch-projects-types">
      <Seo 
        title="Borne d'affichage tactile et interactive"
        description="Nous réalisons des bornes tactiles et interactives sur-mesure ou personalisées selon vos besoins. Votre établissement offrira une borne d'affichage intuitive et attractive pour ses visiteurs."
       />
      <TouchScreen lang="fr-CA" types={data.allContentfulTypeDeTactile} carouselDatas={data.allContentfulTactile.edges[0].node}/>
    </Layout>
  )
}

export default TouchScreenFr
